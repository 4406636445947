define("ember-models-table/components/models-table/cell-content-edit", ["exports", "ember-models-table/templates/components/models-table/cell-content-edit"], function (_exports, _cellContentEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component for cells in the edit-mode.
   *
   * Properties and event-handlers from [models-table/cell](Components.ModelsTableCell.html) are bound here
   *
   * @namespace Components
   * @class ModelsTableCellContentEdit
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _cellContentEdit.default,
    init: function init() {
      Ember.set(this, 'tagName', Ember.get(this, 'themeInstance.tagNames.cell-content'));

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});