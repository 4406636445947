define("ember-models-table/components/models-table/global-filter", ["exports", "ember-models-table/templates/components/models-table/global-filter"], function (_exports, _globalFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Global filter element used within [models-table](Components.ModelsTable.html).
   *
   * Its value is used for all data-items and for each columns `propertyName`.
   *
   * Usage example:
   *
   * ```hbs
   * {{#models-table data=data columns=columns as |mt|}}
   *   {{mt.global-filter}}
   *   {{! .... }}
   * {{/models-table}}
   * ```
   *
   * @namespace Components
   * @class ModelsTableGlobalFilter
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _globalFilter.default,

    /**
     * Bound from {{#crossLink "Components.ModelsTable/filterString:property"}}ModelsTable.filterString{{/crossLink}}
     *
     * @property value
     * @type string
     * @default null
     */
    value: null,

    /**
     * Bound from {{#crossLink "Components.ModelsTable/themeInstance:property"}}ModelsTable.themeInstance{{/crossLink}}
     *
     * @property themeInstance
     * @type object
     * @default null
     */
    themeInstance: null,

    /**
     * Bound from {{#crossLink "Components.ModelsTable/globalFilterUsed:property"}}ModelsTable.globalFilterUsed{{/crossLink}}
     *
     * @property globalFilterUsed
     * @type boolean
     * @default null
     */
    globalFilterUsed: null,

    /**
     * @property inputId
     * @type string
     * @private
     */
    inputId: Ember.computed('elementId', function () {
      return "".concat(Ember.get(this, 'elementId'), "-global-filter");
    }),
    actions: {
      noop: function noop() {}
    }
  });

  _exports.default = _default;
});