define("ember-models-table/themes/ember-semanticui", ["exports", "ember-models-table/themes/semanticui"], function (_exports, _semanticui) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class EmberSemanticUI
   * @namespace Themes
   * @extends Themes.SemanticUI
   */
  var _default = _semanticui.default.extend({
    components: {
      'select': 'models-table/themes/ember-semanticui/select',
      'row-filtering-cell': 'models-table/themes/ember-semanticui/row-filtering-cell'
    }
  });

  _exports.default = _default;
});