define('ember-context-menu/services/context-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var itemHeight = 32;
  var safetyMarginX = 400;
  var safetyMarginY = 32;

  function renderLeft(xPosition, screenWidth) {
    if (!xPosition || !screenWidth) {
      return false;
    }

    var onRightHalf = xPosition > screenWidth * 0.5;
    var spaceRight = screenWidth - xPosition;

    return onRightHalf && spaceRight < safetyMarginX;
  }

  function correctedPositionY(yPosition, screenHeight, itemCount) {
    var estimatedHeight = itemCount * itemHeight + safetyMarginY;
    var breakPoint = screenHeight - estimatedHeight;

    return yPosition > breakPoint ? breakPoint : yPosition;
  }

  exports.default = Ember.Service.extend({
    isActive: false,

    activate: function activate(event, items, selection, details) {
      var clientX = event.clientX,
          clientY = event.clientY;

      var screenWidth = Ember.get(event, 'view.window.innerWidth');
      var screenHeight = Ember.get(event, 'view.window.innerHeight');

      selection = selection ? [].concat(selection) : [];

      this.removeDeactivateHandler();

      if (clientX == null || clientY == null) {
        (true && !(false) && Ember.assert('You need to pass event to the context-menu activate()'));
      }

      if (!(items && items.length)) {
        (true && !(false) && Ember.assert('You need to pass items to the context-menu activate()'));
      }

      Ember.set(this, 'position', {
        left: clientX,
        top: correctedPositionY(clientY, screenHeight, Ember.get(items, 'length'))
      });

      Ember.set(this, 'event', event);
      Ember.set(this, 'items', items);
      Ember.set(this, 'selection', selection);
      Ember.set(this, 'details', details);
      Ember.set(this, 'renderLeft', renderLeft(clientX, screenWidth));
      Ember.set(this, 'isActive', true);

      this.addDeactivateHandler();
    },
    willDestroy: function willDestroy() {
      this.removeDeactivateHandler();
    },
    removeDeactivateHandler: function removeDeactivateHandler() {
      var deactivate = Ember.get(this, 'deactivate');

      if (deactivate != null) {
        Ember.$(document.body).off('click', deactivate);
        Ember.set(this, 'deactivate', null);
      }
    },
    addDeactivateHandler: function addDeactivateHandler() {
      var _this = this;

      var deactivate = function deactivate() {
        return Ember.set(_this, 'isActive', false);
      };
      Ember.set(this, 'deactivate', deactivate);

      Ember.$(document.body).one('click', deactivate);
    }
  });
});