define("ember-models-table/components/models-table/pagination-numeric", ["exports", "ember-models-table/templates/components/models-table/pagination-numeric"], function (_exports, _paginationNumeric) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Numeric navigation used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Usage example:
   *
   * ```hbs
   * {{#models-table data=data columns=columns as |mt|}}
   *   {{#mt.footer as |footer|}}
   *     {{footer.pagination-numeric}}
   *     {{! ... }}
   *   {{/mt.footer}}
   *   {{! .... }}
   * {{/models-table}}
   * ```
   *
   * @class ModelsTablePaginationNumeric
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _paginationNumeric.default,
    classNameBindings: ['themeInstance.paginationWrapper', 'themeInstance.paginationWrapperNumeric'],

    /**
     * Bound from {{#crossLink "Components.ModelsTable/collapseNumPaginationForPagesCount:property"}}ModelsTable.collapseNumPaginationForPagesCount{{/crossLink}}
     *
     * @property collapseNumPaginationForPagesCount
     * @type number
     * @default null
     */
    collapseNumPaginationForPagesCount: null,

    /**
     * Bound from {{#crossLink "Components.ModelsTable/currentPageNumber:property"}}ModelsTable.currentPageNumber{{/crossLink}}
     *
     * @property currentPageNumber
     * @type number
     * @default null
     */
    currentPageNumber: null,

    /**
     * Bound from {{#crossLink "Components.ModelsTable/showCurrentPageNumberSelect:property"}}ModelsTable.showCurrentPageNumberSelect{{/crossLink}}
     *
     * @property showCurrentPageNumberSelect
     * @type boolean
     * @default null
     */
    showCurrentPageNumberSelect: null,

    /**
     * Bound from {{#crossLink "Components.ModelsTable/currentPageNumberOptions:property"}}ModelsTable.currentPageNumberOptions{{/crossLink}}
     *
     * @property currentPageNumberOptions
     * @type object[]
     * @default null
     */
    currentPageNumberOptions: null,

    /**
     * Bound from {{#crossLink "Components.ModelsTable/arrangedContentLength:property"}}ModelsTable.arrangedContentLength{{/crossLink}}
     *
     * @property recordsCount
     * @type number
     * @default null
     */
    recordsCount: null,

    /**
     * Bound from {{#crossLink "Components.ModelsTable/pageSize:property"}}ModelsTable.pageSize{{/crossLink}}
     *
     * @property pageSize
     * @type number
     * @default null
     */
    pageSize: null,

    /**
     * Bound from {{#crossLink "Components.ModelsTable/pagesCount:property"}}ModelsTable.pagesCount{{/crossLink}}
     *
     * @property pagesCount
     * @type number
     * @default null
     */
    pagesCount: null,

    /**
     * Closure action {{#crossLink "Components.ModelsTable/actions.gotoCustomPage:method"}}ModelsTable.actions.gotoCustomPage{{/crossLink}}
     *
     * @event goToPage
     */
    goToPage: null,

    /**
     * Bound from {{#crossLink "Components.ModelsTable/themeInstance:property"}}ModelsTable.themeInstance{{/crossLink}}
     *
     * @property themeInstance
     * @type object
     * @default null
     */
    themeInstance: null,

    /**
     * List of links to the page
     * Used if {{#crossLink "Components.ModelsTable/useNumericPagination:property"}}ModelsTable.useNumericPagination{{/crossLink}} is true
     * @typedef {object} visiblePageNumber
     * @property {boolean} isLink
     * @property {boolean} isActive
     * @property {string} label
     *
     * @type {visiblePageNumber[]}
     * @property visiblePageNumbers
     */
    visiblePageNumbers: Ember.computed('pagesCount', 'currentPageNumber', 'collapseNumPaginationForPagesCount', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'pagesCount', 'currentPageNumber', 'collapseNumPaginationForPagesCount'),
          pagesCount = _EmberGetProperties.pagesCount,
          currentPageNumber = _EmberGetProperties.currentPageNumber,
          collapseNumPaginationForPagesCount = _EmberGetProperties.collapseNumPaginationForPagesCount;

      var notLinkLabel = '...';
      var showAll = pagesCount <= collapseNumPaginationForPagesCount;
      var groups = []; // array of 8 numbers

      var labels = Ember.A([]);
      groups[0] = 1;
      groups[1] = Math.min(1, pagesCount);
      groups[6] = Math.max(1, pagesCount);
      groups[7] = pagesCount;
      groups[3] = Math.max(groups[1] + 1, currentPageNumber - 1);
      groups[4] = Math.min(groups[6] - 1, currentPageNumber + 1);
      groups[2] = Math.floor((groups[1] + groups[3]) / 2);
      groups[5] = Math.floor((groups[4] + groups[6]) / 2);

      if (showAll) {
        for (var i = groups[0]; i <= groups[7]; i++) {
          labels[i] = i;
        }
      } else {
        for (var n = groups[0]; n <= groups[1]; n++) {
          labels[n] = n;
        }

        var userGroup2 = groups[4] >= groups[3] && groups[3] - groups[1] > 1;

        if (userGroup2) {
          labels[groups[2]] = notLinkLabel;
        }

        for (var _i = groups[3]; _i <= groups[4]; _i++) {
          labels[_i] = _i;
        }

        var userGroup5 = groups[4] >= groups[3] && groups[6] - groups[4] > 1;

        if (userGroup5) {
          labels[groups[5]] = notLinkLabel;
        }

        for (var _i2 = groups[6]; _i2 <= groups[7]; _i2++) {
          labels[_i2] = _i2;
        }
      }

      return Ember.A(labels.compact().map(function (label) {
        return {
          label: label,
          isLink: label !== notLinkLabel,
          isActive: label === currentPageNumber
        };
      }));
    }),

    /**
     * @property inputId
     * @type string
     * @private
     */
    inputId: Ember.computed('elementId', function () {
      return "".concat(Ember.get(this, 'elementId'), "-page-number-select");
    }),
    actions: {
      gotoCustomPage: function gotoCustomPage(pageNumber) {
        Ember.get(this, 'goToPage')(pageNumber);
      }
    }
  });

  _exports.default = _default;
});