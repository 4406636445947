define('ember-ace/utils/completion-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var CompletionManager = function () {
    function CompletionManager(_ref) {
      var suggestCompletions = _ref.suggestCompletions,
          renderCompletionTooltip = _ref.renderCompletionTooltip;

      _classCallCheck(this, CompletionManager);

      this._suggestCompletions = suggestCompletions;
      this._renderCompletionTooltip = renderCompletionTooltip;
    }

    // Called by Ace when


    _createClass(CompletionManager, [{
      key: 'getCompletions',
      value: function getCompletions(editor, session, position, prefix, callback) {
        var suggestCompletions = this._suggestCompletions;
        if (!suggestCompletions) return callback(null, []);

        var promise = void 0;
        try {
          promise = Ember.run(function () {
            return suggestCompletions(editor, session, position, prefix);
          });
        } catch (error) {
          promise = Ember.RSVP.reject(error);
        }

        Ember.RSVP.resolve(promise).then(function (results) {
          return callback(null, results);
        }).catch(function (error) {
          return callback(error);
        });
      }
    }, {
      key: 'getDocTooltip',
      value: function getDocTooltip(result) {
        result.docHTML = this._renderCompletionTooltip.call(null, result);
      }
    }]);

    return CompletionManager;
  }();

  exports.default = CompletionManager;
});